<template>
  <div class="footer">
    <div class="container">
      <div class="row">
        <!-- Contact Address Section -->
        <div class="footer-column col-md-3 col-sm-6 col-xs-12">
          <div class="footer-widget address-widget">
            <h3 style="text-align: left; font-size: 16px;">
              <i class="fa fa-map"></i> Contact Address
            </h3>
            <ul>
              <li>
                <a href="#">
                  <span class="theme_color fa fa-bank"></span> Gandaki Province Policy and Planning Commission
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="theme_color fa fa-map-marker"></span> Pokhara, Nepal
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="theme_color fa fa-phone"></span> 061-455362, 061-452102, 061-452672
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="theme_color fa fa-fax"></span>
                </a>
              </li>
              <li>
                <a href="#">
                  <span class="theme_color fa fa-envelope"></span> pppcgandaki@gmail.com, info@ppc.gandaki.gov.np
                </a>
              </li>
            </ul>
          </div>
        </div>

        <!-- Related Links Section -->
        <div class="footer-column col-md-4 col-xs-12">
          <h3 style="font-size: 16px;" class="content_title">
            <i class="fa fa-link"></i> <span>&nbsp;&nbsp; Related Link</span>
          </h3>
          <template>
            <div class="panel-body">
              <ul class="link-ul">
                <li v-for="(link, index) in displayedLinks" :key="index">
                  <a :href="link.href" target="_blank" :title="'visit : ' + link.title">{{ link.title }}</a>
                </li>
              </ul>
              <p>
                <button v-if="!showAll" @click="showAll = true" class="btn btn-sm btn-success float-right">
                  <i class="fa fa-plus"></i> View All
                </button>
                <button v-if="showAll" @click="showAll = false" class="btn btn-sm btn-danger float-right">
                  <i class="fa fa-minus"></i> Show Less
                </button>
              </p>
            </div>
          </template>
        </div>

        <!-- Office's Location Section -->
        <div class="footer-column col-md-5 col-sm-12 col-xs-12">
          <h3 class="content_title" style="font-size: 16px;">
            <i class="fa fa-map-marker"></i> <span>&nbsp;&nbsp; Office's Location</span>
          </h3>
          <div class="panel-body">
            <iframe
              src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3516.1815177956955!2d83.96668367427499!3d28.20179720351259!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x399595ac8753dd93%3A0x14b7e25b0fd1bca3!2sProvincial%20Policy%20and%20Planning%20Commission%2C%20Gandaki%20Pokhara!5e0!3m2!1sen!2snp!4v1701754882337!5m2!1sen!2snp&markers=color:red%7Clabel:%7C28.20179720351259,83.96668367427499"
              width="100%" height="242 px" style="border:0;" allowfullscreen="" loading="lazy"
              referrerpolicy="no-referrer-when-downgrade"></iframe>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FooterComponent',
  data() {
    return {
      showAll: false,
      links: [
        { href: "https://mof.gandaki.gov.np/", title: "Ministry of Economic Affairs, Gandaki Province,Pokhara" },
        { href: "https://mopid.gandaki.gov.np/", title: "Ministry of Physical Infrastructure Development and Transport Management, Gandaki Province, Pokhara" },
        { href: "#", title: "Ministry of Agriculture, land Management Gandaki Province" },
        { href: "https://mofesc.gandaki.gov.np/", title: "Ministry of Industry, Tourism, Forest and Environment Gandaki Province" },
        { href: "https://pradeshsabha.gandaki.gov.np/", title: "Provincial Assembly , Gandaki Province" },
        { href: "https://oca.gandaki.gov.np/", title: "Office of the Chief Attorney Gandaki Province" },
        { href: "http://ppc.gandaki.gov.np/", title: "Office of the Chief Minister and Council of Ministers" },
        { href: "https://ppsc.gandaki.gov.np/", title: "Province Public Service Commission Gandaki Province" },
        { href: "http://gpta.gandaki.gov.np/", title: "Gandaki Province Training Academy" },
        { href: "https://www.gandakiuniversity.edu.np/", title: "Gandaki University" },
        { href: "http://moewrws.gandaki.gov.np/", title: "Ministry of Energy, Water Resource and Water Supply" },
      ],
    };
  },
  computed: {
    displayedLinks() {
      return this.showAll ? this.links : this.links.slice(0, 5);
    },
  },
};
</script>

<style scoped>
.footer {
  background-color: #19398a;
  /* Blue background color */
  color: white;
  /* Text color */
  padding: 20px 0;
  /* Padding for top and bottom */
}

.footer-column {
  margin-bottom: 20px;
  /* Margin for spacing between columns */
}

.content_title {
  color: white;
  /* Header text color */
}

.footer-widget ul {
  list-style: none;
  /* Remove default list styles */
  padding: 0;
  /* Remove default padding */
}

.footer-widget ul li {
  margin-bottom: 10px;
  /* Margin for list items */
}

.footer-widget ul li a {
  color: white;
  /* Link color */
  text-decoration: none;
  /* Remove underline from links */
  font-size: 12px;
  /* Smaller font size for links */
}

.footer-widget ul li a:hover {
  text-decoration: underline;
  /* Underline on hover */
}

.panel-body {
  background-color: #1e4aa1;
  /* Slightly different shade for panel background */
  padding: 10px;
  /* Padding inside panel */
  color: white;
}

.btn-success {
  background-color: #4caf50;
  /* Green button background */
  border: none;
  /* Remove button border */
}

.link-ul {
  color: white;
  /* Ensure link list text is white */
  font-size: 14px;
  /* Smaller font size for related links */
}

.link-ul li a {
  color: white;
  /* Ensure links in list are white */
  font-size: 12px;
  /* Smaller font size for links */
}
</style>
