<template>
  <section class="topmap mt-4">
    <div class="container">
      <div class="row">
        <div class="col-md-4">
          <div class="mapdataleft">
            <total-count></total-count>
            <!-- Category -->
            <div class="availablelayers">
              <div class="card border-0 card-widget h-100">
                <div class="card-body p-0">
                  <div class="d-flex justify-content-between">
                    <div class="card_head">
                      <h6>Available Category</h6>
                    </div>
                    <button v-if="$store.state.auth.isAdmin || $store.state.auth.isSuperuser"
                      class="btn btn-primary rounded-0 mb-2 mb-lg-0 addnewbtn allbtn manage_category"
                      data-bs-toggle="modal" data-bs-target="#manageCategoryModal">
                      <span class="d-flex align-items-center"><span class="small">Manage Category</span></span>
                    </button>
                  </div>
                  <ul class="list-unstyled row pad1 pt-0 pb-0 mt-3">
                    <template v-if="categoryLoading">
                      <li class="text-capitalize col-md-6 pb-2" v-for="i in 10" :key="i">
                        <span class="li_skeleton">Category is Loading {{ i }}</span>
                      </li>
                    </template>
                    <template v-else>
                      <template v-for="(category, index) in categories">
                        <li v-if="index < 10" class="pb-2 text-capitalize col-md-6" :key="category.id">
                          <input :id="'category-' + category.id" class="form-check-input mt-2 me-2" type="checkbox"
                            name="Category" :value="category.Category" v-model="selection.category"
                            @change="getLayers(category.Category)" data-bs-toggle="modal"
                            data-bs-target="#viewallayerModal" />
                          <label :for="'category-' + category.id">
                            {{ category.Category.replaceAll("_", " ") }}</label>
                        </li>
                      </template>
                    </template>
                  </ul>
                  <div class="col-md-12 px-0 rounded-3">
                    <button class="btn btn-primary allbtn float-start manage_layers addnewbtn" data-bs-toggle="modal"
                      data-bs-target="#manageLayerModal"
                      v-if="$store.state.auth.isAdmin || $store.state.auth.isSuperuser">
                      <span class="d-flex align-items-center"><span class="small">Manage Layers</span></span>
                    </button>
                    <button class="btn btn-primary allbtn float-end view_layers addnewbtn px-4" data-bs-toggle="modal"
                      data-bs-target="#viewallayerModal" @click="getLayers">
                      <span class="d-flex align-items-center"><span class="small">View All Category</span></span>
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <!-- End of Category -->
          </div>
        </div>

        <!-- Map -->
        <div class="col-md-8">
          <div class="mapdataright mt-4 mt-md-0 h-100 d-flex flex-column justify-content-between">
            <div ref="document" class="my_map_box h-100">
              <vl-map id="my_map" ref="map" style="height: 500px" data-projection="EPSG:4326"
                :load-tiles-while-animating="true" :load-tiles-while-interacting="true">
                <vl-view :zoom.sync="map.zoom" :center.sync="map.center" :rotation.sync="map.rotation"></vl-view>

                <vl-layer-tile :visible="tileLayer">
                  <vl-source-osm :attributions="[]"></vl-source-osm>
                </vl-layer-tile>

                <vl-interaction-select :features.sync="selected">
                  <vl-overlay class="feature-popup" v-for="feature in selected" :key="feature.id" :id="feature.id"
                    :position="pointOnSurface(feature.geometry)" :auto-pan="true"
                    :auto-pan-animation="{ duration: 300 }">
                    <template slot-scope="">
                      <section class="card" style="height: 10rem; overflow-y: scroll">
                        <div class="card-content">
                          <div class="content p-2">
                            <template v-for="(
                                property, key, index
                              ) in feature.properties">
                              <!--                              v-if="selection.attributes.includes(key)"-->
                              <div class="fa-sm text-capitalize" :key="index">
                                <template v-if="typeof property !== 'object' &&
                      !key.includes('id')
                      ">
                                  <strong>{{ key }}</strong>: {{ property }}
                                </template>
                              </div>
                            </template>
                          </div>
                        </div>
                      </section>
                    </template>
                  </vl-overlay>
                </vl-interaction-select>

                <template v-if="selection.layers.length > 0 && !loading">
                  <template v-for="layer in selection.layers">
                    <vl-layer-vector :opacity="layerOpacity" :key="String(layer.id) + String(layer.slug)" :z-index="layer.shapeType === 'Point'
                      ? 999999999
                      : layer.shapeType === 'LineString'
                        ? 10
                        : 5
                      ">
                      <vl-source-vector>
                        <vl-feature v-for="(feature, index) in layer.features" :key="String(layer.id) + String(index)"
                          :id="String(layer.id) + String(index)" :properties="feature.properties">
                          <vl-geom-multi-polygon :coordinates="feature.geometry.coordinates"
                            v-if="feature.geometry.type === 'MultiPolygon'"></vl-geom-multi-polygon>
                          <vl-geom-polygon :coordinates="feature.geometry.coordinates"
                            v-if="feature.geometry.type === 'Polygon'"></vl-geom-polygon>
                          <vl-geom-point :coordinates="feature.geometry.coordinates"
                            v-if="feature.geometry.type === 'Point'"></vl-geom-point>
                          <vl-geom-line-string :coordinates="feature.geometry.coordinates"
                            v-if="feature.geometry.type === 'LineString'"></vl-geom-line-string>

                          <template v-if="feature.geometry.type === 'Point'">
                            <vl-style>
                              <vl-style-text :text="feature.properties.AAN"
                                font=" bold 10px/1  sans-serif"></vl-style-text>
                              <vl-style-circle :radius="layer.selectedAttributes
                      ? getRadius(
                        feature.properties[
                        layer.selectedAttributes
                        ],
                        layer.range
                      )
                      : 8
                      ">
                                <vl-style-fill color="darkgreen"></vl-style-fill>
                                <vl-style-stroke color="white"></vl-style-stroke>
                              </vl-style-circle>
                            </vl-style>
                          </template>

                          <template v-if="(feature.geometry.type === 'MultiPolygon' ||
                      feature.geometry.type === 'Polygon') &&
                      layer.selectedAttributes
                      ">
                            <vl-style>
                              <!-- vl-style deprecated -->
                              <vl-style-text :text="feature.properties.AAN"
                                font=" bold 10px/1  sans-serif"></vl-style-text>
                              <vl-style-fill :color="getColor(
                      feature.properties[
                      layer.selectedAttributes
                      ],
                      layer.range
                    )
                      "></vl-style-fill>
                              <vl-style-stroke color="white"></vl-style-stroke>
                            </vl-style>
                          </template>

                          <template v-if="feature.geometry.type === 'LineString'">
                            <vl-style>
                              <vl-style-text :text="feature.properties.AAN"
                                font=" bold 10px/1  sans-serif"></vl-style-text>
                              <vl-style-stroke color="blue" :width="3"></vl-style-stroke>
                            </vl-style>
                          </template>
                        </vl-feature>
                      </vl-source-vector>
                    </vl-layer-vector>
                  </template>
                </template>
              </vl-map>
              <img class="my_compass" src="@/assets/img/compass.png" alt="compass" v-if="!loading" />
              <div class="my_legend" id="my_legend">
                <div class="legendbox border p-3 d-none d-md-block" v-if="selection.layers.length > 0 && !loading">
                  <div class="my-legend">
                    <div class="mainhead d-flex align-items-center">
                      <h5 class="fw-bold">Legends</h5>
                    </div>
                    <div class="legend-scale" v-for="(layer, index) in selection.layers" :key="layer.id">
                      <template v-if="layer.File_Type.Filetype === 'Raster'">
                        <div class="legend_layer_title">
                          {{ layer.Layer_Name }}
                        </div>
                        <ul class="legend-labels ms-2">
                          <template v-if="layer.range && layer.range.length > 1">
                            <li>
                              <span :style="{ background: rasterColor[0] }"></span>
                              {{ layer.range[0] }} - {{ layer.range[1] }}
                            </li>
                            <li>
                              <span :style="{ background: rasterColor[1] }"></span>
                              {{ layer.range[2] }} - {{ layer.range[3] }}
                            </li>
                            <li>
                              <span :style="{ background: rasterColor[2] }"></span>
                              {{ layer.range[4] }} - {{ layer.range[5] }}
                            </li>
                            <li>
                              <span :style="{ background: rasterColor[3] }"></span>
                              {{ layer.range[6] }} - {{ layer.range[7] }}
                            </li>
                            <li>
                              <span :style="{ background: rasterColor[4] }"></span>
                              {{ layer.range[8] }} - {{ layer.range[9] }}
                            </li>
                          </template>
                        </ul>
                        <hr class="my-3" />
                      </template>
                      <template v-else>
                        <template v-if="layer.shapeType === 'LineString'">
                          <div class="legend_layer_title">
                            {{ layer.Layer_Name }}
                          </div>
                          <ul class="legend-labels ms-2">
                            <li>
                              <span style="background-color: blue"></span>
                              {{ layer.Layer_Name }}
                            </li>
                          </ul>
                          <hr class="my-3" />
                        </template>
                        <template v-if="selection.attributes[index] &&
                      selection.attributes[index].length > 0 &&
                      layer.shapeType !== 'LineString'
                      ">
                          <template>
                            <div class="legend_layer_title">
                              {{ layer.Layer_Name }}
                            </div>
                            <template v-if="layer.shapeType === 'Point' &&
                      layer.range.length > 0
                      ">
                              <span class="ms-2 text-capitalize legend_selected_attribute">{{
                      layer.selectedAttributes.replaceAll("_", " ")
                    }}</span>
                              <ul class="legend-labels ms-2">
                                <template v-if="layer.range.length > 1">
                                  <li v-for="i in 5" :key="i">
                                    <div class="d-flex justify-content-center" style="width: 20px; margin-right: 10px">
                                      <div class="point" :class="'point-' + i" :style="{ background: 'darkgreen' }">
                                      </div>
                                    </div>
                                    {{ layer.range[i - 1] }} -
                                    {{ layer.range[i] }}
                                  </li>
                                </template>
                                <li v-else>
                                  <div class="d-flex justify-content-center" style="width: 20px; margin-right: 10px">
                                    <div class="point point-3"></div>
                                  </div>
                                  {{ layer.range[0] }}
                                </li>
                              </ul>
                            </template>
                            <template v-else-if="(layer.shapeType === 'MultiPolygon' ||
                      layer.shapeType === 'Polygon') &&
                      layer.range.length > 0
                      ">
                              <div class="ms-2 text-capitalize legend_selected_attribute">
                                {{
                      layer.selectedAttributes.replaceAll("_", " ")
                    }}
                              </div>
                              <ul class="legend-labels ms-2">
                                <template v-if="layer.range.length > 1">
                                  <li v-for="i in 5" :key="i">
                                    <span :style="{ background: colors[i - 1] }"></span>
                                    {{ layer.range[i - 1] }} -
                                    {{ layer.range[i] }}
                                  </li>
                                </template>
                                <li v-else>
                                  <div class="d-flex justify-content-center" style="width: 20px; margin-right: 10px">
                                    <span></span>
                                  </div>
                                  {{ layer.range[0] }}
                                </li>
                              </ul>
                            </template>
                          </template>
                          <hr class="my-3" />
                        </template>
                      </template>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="mappara shadow">
              <div class="row py-2 h-100">
                <div class="col-md-3 d-flex align-items-center">
                  <div class="btn-group mx-4">
                    <button class="btn btn-secondary sec1 btn-sm dropdown-toggle" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      Selected Layers
                    </button>
                    <ul class="dropdown-menu border-0 shadow" style="
                        height: 20rem;
                        overflow-y: scroll;
                        overflow-x: hidden;
                      ">
                      <li class="row">
                        <div class="col-md-12" v-for="(layer, index) in selection.layers" :key="index">
                          <div class="form-check">
                            <input class="form-check-input" type="checkbox" :id="`layer-${index}`"
                              v-model="selection.layers" :value="layer" @change="removeLayer(layer.Layer_Name)" />
                            <label class="form-check-label" :for="`layer-${index}`">
                              <i class="fas fa-ellipsis-v"></i>
                              {{ layer.Layer_Name }}
                            </label>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </div>
                  <div class="btn-group mx-md-auto me-auto">
                    <button class="btn btn-secondary sec1 btn-sm dropdown-toggle" type="button"
                      data-bs-toggle="dropdown" aria-expanded="false">
                      Map Attributes
                    </button>
                    <ul class="dropdown-menu border-0 shadow" style="
                        height: 20rem;
                        overflow-y: scroll;
                        overflow-x: hidden;
                      ">
                      <li class="row" v-if="selection.layers.length > 0">
                        <div class="col-md-12" v-for="(layer, index) in selection.layers" :key="layer.id">
                          <template v-if="layer.shapeType !== 'LineString' &&
                      layer.File_Type.Filetype !== 'Raster'
                      ">
                            <strong>{{ layer.Layer_Name }}</strong>

                            <div class="form-check" v-for="attribute in layer.attributes" :key="attribute">
                              <input class="form-check-input" type="radio" :id="`${index}-attrs-${attribute}`"
                                :name="'selection' + index" v-model="selection.attributes[index]" :value="attribute"
                                @change="rangeCalculator(index, attribute)" />
                              <label class="form-check-label" :for="`${index}-attrs-${attribute}`">
                                <i class="fas fa-ellipsis-v"></i>
                                {{ attribute }}
                              </label>
                            </div>
                          </template>
                        </div>
                      </li>
                    </ul>
                  </div>
                </div>
                <div class="col-md-9 d-flex align-items-center justify-content-end pe-4">
                  <div v-if="$store.state.auth.isLoggedIn">
                    <button v-if="!exportingMap" class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 viewallbtn allbtn"
                      @click.prevent="startExport">
                      <span class="d-flex align-items-center"><span class="small fw-bold">Export Map</span></span>
                    </button>
                    <button v-else class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn"
                      type="button" disabled>
                      <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                      Loading...
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- End of Map -->
      </div>
    </div>

    <layers-add-new-modal></layers-add-new-modal>

    <!-- <category-add-modal @added="getCategories"></category-add-modal> -->

    <div class="modal fade" id="layers-view-modal" tabindex="-1" aria-labelledby="viewalcatModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewalcatModalLabel">Layers</h5>
            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            + <div class="filter_right">
              <form class="" method="get" action="">
                <div class="form-check form-check-inline" v-for="letter in letters" :key="letter">
                  <input type="checkbox" class="form-check-input" :id="letter" v-model="selection.sortLayer"
                    :value="letter" />
                  <label class="btn btn-rounded small" :class="selection.sortLayer.includes(letter)
                      ? 'btn-primary'
                      : 'btn-outline-primary'
                      " :for="letter">
                    {{ letter }}
                  </label>
                </div>

                <button class="btn btn-outline-primary btn-sm ms-2" @click.prevent="selection.sortLayer = []">
                  Clear
                </button>
              </form>
            </div>
            <hr />

            <div class="layer-inner mt-2">
              <p class="mb-0 fw-bold pb-0">Available Layers</p>
              <div class="row">
                <div class="col-md-3 layerinner" v-for="(layer, index) in sortedLayers" :key="index">
                  <div class="form-check">
                    <input :checked="selection.layers.some((el) => {
                      return el.id === layer.id;
                    })
                      " class="form-check-input" type="checkbox" :id="'layer-' + layer.id" @input="selectLayer(layer)"
                      :value="layer" />
                    <label class="form-check-label" :for="'layer-' + layer.id">
                      <i class="fas fa-ellipsis-v"></i> {{ layer.Layer_Name }}
                    </label>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="mybtn">
              <button v-if="!addingToMap" class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-2 addnewbtn allbtn"
                @click.prevent="mapLayers('view')">
                <span class="d-flex align-items-center"><span class="small">Add Layer to Map</span></span>
              </button>
              <button v-else class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn" type="button"
                disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
              <button ref="closeBtn" class="btn btn-danger border-0 rounded-0 px-2 mb-2 mb-lg-0 allbtn"
                @click.prevent="toggleModal('view')">
                <span class="d-flex align-items-center"><span class="small">Close</span></span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="viewallayerModal" tabindex="-1" aria-labelledby="viewallayerModalLabel"
      aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered modal-xl">
        <div class="modal-content">
          <div class="modal-header">
            <h5 class="modal-title" id="viewallayerModalLabel">Layers</h5>

            <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-md-3">
                <div class="filterbar p-2">
                  <div class="box border-bottom">
                    <div class="box-label text-uppercase d-flex align-items-center justify-content-between">
                      Category
                    </div>
                    <div id="inner-box" class="mt-2 mr-1 category_listing_box">
                      <ul class="list-unstyled row pad1 pt-0 pb-0 mt-3">
                        <li class="pb-2 text-capitalize" v-for="category in categories" :key="'key-' + category.id">
                          <a class="fw-bold" href="#">
                            <input :id="'category-all-' + category.id" class="form-check-input mt-2 me-2"
                              type="checkbox" name="Category" :value="category.Category" v-model="selection.category"
                              @change="getLayers(category.Category)" />
                            <label :for="'category-all-' + category.id">
                              {{
                      category.Category.replaceAll("_", " ")
                    }}</label>
                          </a>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div class="col-md-9">
                <div class="filter_right">
                  <form>
                    <div class="form-check form-check-inline" v-for="letter in letters" :key="letter">
                      <input type="checkbox" class="form-check-input" :id="'all' + letter" v-model="selection.sortLayer"
                        :value="letter" />
                      <label class="btn btn-rounded small" :class="selection.sortLayer.includes(letter)
                      ? 'btn-primary'
                      : 'btn-outline-primary'
                      " :for="'all' + letter">
                        {{ letter }}
                      </label>
                    </div>

                    <button class="btn btn-outline-primary btn-sm ms-2" @click.prevent="selection.sortLayer = []">
                      Clear
                    </button>
                  </form>
                </div>
                <hr />

                <div class="category_list" v-if="!layerLoading">
                  <div class="row" v-for="(item, index) in selection.category" :key="index">
                    <hr v-if="index !== 0" class="my-3" />
                    <div class="col-12 text-capitalize category_label">
                      <i class="fas fa-globe"></i>
                      <strong>{{ item.replaceAll("_", " ") }}</strong>
                    </div>
                    <div class="row" v-if="filteredSubcategories(item).length > 0">
                      <div class="row" v-for="(subitem, index) in filteredSubcategories(item)" :key="index">
                        <div class="col-12 text-capitalize subcategory_list">
                          <i class="fas fa-caret-square-right"></i>
                          <span class="pl-2">{{ subitem.title }}</span>
                        </div>
                        <template v-for="layer in sortedLayers">
                          <div class="col-md-4 layerinner category_layers" v-if="layer.subcategory.id === subitem.id"
                            :key="layer.id">
                            <div class="form-check" v-if="layer.Layer_Name">
                              <input :checked="selection.layers.some((el) => {
                      return el.id === layer.id;
                    })
                      " class="form-check-input" type="checkbox" :id="'layer-all-' + layer.id"
                                @input="selectLayer(layer)" :value="layer" />
                              <label class="form-check-label fw-normal text-primary" :for="'layer-all-' + layer.id">
                                <i class="fas fa-ellipsis-v"></i>
                                {{ layer.Layer_Name }}
                              </label>
                            </div>
                            <div v-else>Layers not available</div>
                          </div>
                        </template>
                      </div>
                    </div>
                    <div v-else>
                      Layers Not Available
                    </div>
                  </div>
                </div>
                <div class="d-flex justify-content-center align-items-center category_list_spinner" v-else>
                  <div class="spinner-border" role="status">
                    <span class="visually-hidden">Loading...</span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <div class="mybtn">
              <span class="text-danger me-3" v-if="selectionError">({{ selectionError }})</span>
              <button v-if="!addingToMap" class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn"
                @click.prevent="mapLayers('all')">
                <span class="d-flex align-items-center"><span class="small">Add to Map</span></span>
              </button>
              <button v-else class="btn btn-primary rounded-0 px-2 mb-2 mb-lg-0 me-3 addnewbtn allbtn" type="button"
                disabled>
                <span class="spinner-border spinner-border-sm" role="status" aria-hidden="true"></span>
                Loading...
              </button>
              <!--  <button
                class="btn btn-danger border-0 rounded-0 px-2 mb-2 mb-lg-0  allbtn"
                @click.prevent="toggleModal('all')"
                :disabled="mapLoading"
              >
                <span class="d-flex align-items-center"
                  ><span class="small">Close</span></span
                >
              </button> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <manage-category-modal @categoryUpdated="getCategories"></manage-category-modal>

    <manage-layers-modal></manage-layers-modal>
  </section>
</template>

<script>
import TotalCount from "./TotalCount";
import LayersAddNewModal from "../../modals/LayersAddNewModal";
// import CategoryAddModal from "../../modals/CategoryAddModal";
import ManageCategoryModal from "../../modals/ManageCategoryModal";
import ManageLayersModal from "../../modals/ManageLayersModal";

import ImageStatic from "ol/source/ImageStatic";
import { ScaleLine } from "ol/control";
import { findPointOnSurface } from "vuelayers/dist/ol-ext";

import { toPng } from "html-to-image";
import download from "downloadjs";
import shp from "shpjs";
import { fromArrayBuffer } from "geotiff";
import * as plotty from "plotty";
import ImageLayer from "ol/layer/Image";
import Graticule from "ol/layer/Graticule";
import Stroke from "ol/style/Stroke";
export default {
  name: "HeroSection",
  components: {
    ManageLayersModal,
    ManageCategoryModal,
    // CategoryAddModal,
    LayersAddNewModal,
    TotalCount,
  },
  data() {
    return {
      tileLayer: true,
      exportingMap: false,
      addingToMap: false,
      selectionError: "",
      mapImgSource: "",
      layerOpacity: 0.8,
      loading: true,
      categoryLoading: true,
      layerLoading: false,
      mapLoading: false,
      modal: null,
      viewAllModal: null,
      manageCategoryModal: null,
      categories: [],
      layers: [],
      map: {
        zoom: 11,
        center: [83.96934, 28.20268],
        rotation: 0,
      },
      selected: [],
      selection: {
        category: [],
        layers: [],
        attributes: [],
        sortLayer: [],
      },
      radius: [5, 8, 11, 14, 17],
      colors: [],
      rasterColor: [],
    };
  },
  computed: {
    letters() {
      let letters = [];
      for (let i = "A".charCodeAt(0); i <= "Z".charCodeAt(0); i++) {
        letters.push(String.fromCharCode([i]));
      }
      return letters;
    },
    sortedLayers() {
      if (this.selection.sortLayer.length > 0) {
        return this.layers.filter((el) => {
          let found = false;
          this.selection.sortLayer.forEach((letter) => {
            if (
              el.Layer_Name &&
              el.Layer_Name.toLowerCase().startsWith(letter.toLowerCase())
            ) {
              found = true;
            }
          });
          return found;
        });
      } else {
        return this.layers;
      }
    },

  },
  mounted() {
    this.getCategories();
    this.modal = new this.$bootstrap.Modal(
      document.getElementById("layers-view-modal")
    );
    this.viewAllModal = new this.$bootstrap.Modal(
      document.getElementById("viewallayerModal")
    );

    this.colors[0] = "#ff8f8f";
    this.colors[1] = "#ff6b6b";
    this.colors[2] = "#ff4747";
    this.colors[3] = "#ff2424";
    this.colors[4] = "#910c05";
    this.$emit("colors", this.colors);
    this.rasterColor[0] = "#FF7F7F";
    this.rasterColor[1] = "#377eb8";
    this.rasterColor[2] = "#4daf4a";
    this.rasterColor[3] = "#FFFF00";
    this.rasterColor[4] = "#ff7f00";

    this.$emit("rasterColor", this.rasterColor);

    setTimeout(() => {
      const gridLines = new Graticule({
        // the style to use for the lines, optional.
        strokeStyle: new Stroke({
          color: "#A8A9AD",
          width: 1,
          lineJoin: "bevel",
        }),
        opacity: 0.7,
        showLabels: true,
        wrapX: false,
        zIndex: 10,
        latLabelPosition: 1,
        // intervals: [60, 45, 30, 20, 10, 5, 1, 0.5, 0.3],
      });

      gridLines.set("name", "gridline");

      this.$refs.map.$map.addLayer(gridLines);
    }, 2000);
  },
  methods: {
    filteredSubcategories(category_input) {
      const categoryFiltered = this.categories.find(cat => cat.Category === category_input);
      return categoryFiltered ? categoryFiltered.subcategories : [];
    },
    selectLayer(layer) {
      const doesContain = this.selection.layers.some((el) => {
        return el.id === layer.id;
      });

      if (doesContain) {
        const pos = this.selection.layers
          .map((el) => {
            return el.id;
          })
          .indexOf(layer.id);

        if (pos > -1) {
          this.removeLayer(layer.Layer_Name);
          this.selection.layers.splice(pos, 1);
        }
      } else {
        this.selection.layers.push(layer);
      }
    },
    async getCategories() {
      await this.$repository.category.get().then((res) => {
        console.log(res);
        this.categories = res.data.results;
        this.loading = false;
        this.categoryLoading = false;

        let scaleLine = new ScaleLine({
          units: "metric",
          bar: true,
          steps: 4,
          text: false,
          minWidth: 130,
        });

        this.$refs.map.$map.addControl(scaleLine);
      });
    },
    async getLayers(category) {
      this.layerLoading = true;
      if (!this.selection.category.includes(category)) {
        this.selection.layers.forEach((el) => {
          if (el.Category.Category === category) {
            const pos = this.selection.layers
              .map(function (e) {
                return e.Category.Category;
              })
              .indexOf(category);
            this.removeLayer(el.Layer_Name);
            this.selection.layers.splice(pos, 1);
          }
        });
      }

      if (this.selection.category.length > 0) {
        /*  this.selection.layers.forEach((el) => {
          this.removeLayer(el.Layer_Name);
        }); */
        //this.selection.layers = [];
        this.layers = [];
        for (const cat of this.selection.category) {
          let params = `?Category=${cat}`;
          await this.$repository.document.filter(params).then((res) => {
            if (res.data.results.length > 0) {
              res.data.results.forEach((el) => {
                this.layers.push(el);
              });
            } else {
              // this.layers.push({
              //   Category: {
              //     Category: cat,
              //   },
              //   Layer_Name: null,
              //   id: Math.floor(Math.random()),
              // });
            }
          });
          this.layers = this.getUniqueLayers(this.layers, "id");
        }
      }
      this.layerLoading = false;
    },
    getUniqueLayers(arr, key) {
      return [...new Map(arr.map((item) => [item[key], item])).values()];
    },
    async getDocument(url) {
      const resp = await fetch(url);
      if (resp.status > 299) {
        throw new Error(resp.statusText);
      }
      return resp.arrayBuffer();
    },
    newLayerAdded() {
      this.$refs.addLayerBtn.click();
      this.getLayers();
    },
    getRadius(value, range) {
      if (range.length > 1) {
        if (value < range[1]) {
          return this.radius[0];
        } else if (value < range[2]) {
          return this.radius[1];
        } else if (value < range[3]) {
          return this.radius[2];
        } else if (value < range[4]) {
          return this.radius[3];
        } else {
          return this.radius[4];
        }
      } else {
        return this.radius[1];
      }
    },
    getColor(value, range) {
      if (range.length > 0) {
        if (value < range[1]) {
          return this.colors[0];
        } else if (value < range[2]) {
          return this.colors[1];
        } else if (value < range[3]) {
          return this.colors[2];
        } else if (value < range[4]) {
          return this.colors[3];
        } else {
          return this.colors[4];
        }
      } else {
        return this.colors[2];
      }
    },
    rangeCalculator(index, attribute) {
      this.selection.layers[index].range = [];
      this.selection.layers[index].selectedAttributes = attribute;
      let numbers = [];

      this.selection.layers[index].features.forEach((feature) => {
        const current = feature.properties[attribute];
        numbers.push(current);
      });
      const min = this.arrayMin(numbers);
      const max = this.arrayMax(numbers);
      const divider = (max - min) / 5;

      if (divider > 0) {
        const first = min;
        const second = first + divider;
        const third = second + divider;
        const fourth = third + divider;
        const fifth = fourth + divider;

        this.selection.layers[index].range.push(first.toFixed(2));
        this.selection.layers[index].range.push(second.toFixed(2));
        this.selection.layers[index].range.push(third.toFixed(2));
        this.selection.layers[index].range.push(fourth.toFixed(2));
        this.selection.layers[index].range.push(fifth.toFixed(2));
        this.selection.layers[index].range.push(max.toFixed(2));
      } else {
        this.selection.layers[index].range.push(min);
      }
    },
    raterRangeCalculator(min, max, layer) {
      let divider = (max - min) / 5;

      divider = divider < 1 ? Number(parseFloat(divider)) : Math.floor(divider);

      const second = min + divider;
      const third = second + divider;
      const fourth = third + divider;
      const fifth = fourth + divider;

      if (divider < 1) {
        layer.range.push(
          min,
          second,
          second + 0.01,
          third,
          third + 0.01,
          fourth,
          fourth + 0.01,
          fifth,
          fifth + 0.01,
          max
        );
      } else {
        layer.range.push(
          min,
          second,
          second + 1,
          third,
          third + 1,
          fourth,
          fourth + 1,
          fifth,
          fifth + 1,
          max
        );
      }

      layer.range = layer.range.map((v) => {
        return parseFloat(v).toFixed(2);
      });
    },
    toggleModal(type) {
      this.selection.sortLayer = [];
      this.layers = [];
      //this.selection.category = [];
      // this.selection.layers = [];

      if (type === "all") {
        this.viewAllModal.toggle();
      }

      if (type === "view") {
        this.modal.toggle();
      }

      this.loading = false;
    },
    removeLayer(layer) {
      this.$refs.map.$map.getLayers().forEach((el) => {
        if (el.get("name") === layer) {
          this.$refs.map.$map.removeLayer(el);
        }
      });
    },
    mapLayers(fromModal) {
      this.addingToMap = true;
      if (fromModal === "all") {
        if (this.selection.layers.length === 0) {
          this.selectionError = "Selection layer required";
          this.addingToMap = false;
          return;
        } else {
          this.selectionError = "";
        }
      }
      let temp_layer;
      this.mapLoading = true;
      this.selection.layers.forEach(async (layer, index) => {
        if (layer.File_Type.Filetype === "Raster") {
          layer.range = [];
          await this.onGeotiffLoaded(layer);
        } else {
          this.getDocument(layer.document).then(
            async (shapeFile) =>
              await shp(shapeFile).then(async (geoJson) => {
                temp_layer = layer;

                layer.features = await geoJson.features;

                let attrs = layer.features[0].properties;
                let newAttrs = [];
                for (let key in attrs) {
                  if (typeof attrs[key] === "number") {
                    newAttrs.push(key);
                  }
                }
                layer.attributes = await newAttrs;

                layer.color = this.$randomColor();

                layer.shapeType = layer.features[0].geometry.type;
                layer.temp_layer_type = await temp_layer.shapeType;
                //this.selection.attributes[index] = [];
                //layer.selectedAttributes = null;
                layer.range = [];
                this.removeDuplicatePolygon();
                this.rangeCalculator(index, layer.attributes[0]);
                layer.selectedAttributes = layer.attributes[0];
                this.selection.attributes[index] = layer.attributes[0];
                // this.sortSelectionLayers();
              })
          );
        }
      });

      this.selection.sortLayer = [];
      this.$emit("selectionLayer", this.selection);

      setTimeout(() => {
        this.mapLoading = false;
        this.toggleModal(fromModal);
        this.addingToMap = false;
        this.map.zoom = 10;
      }, 2000);
    },
    sortSelectionLayers() {
      this.selection.layers.forEach((el) => {
        if (el.File_Type.Filetype === "Raster") {
          this.array_move(
            this.selection.layers.indexOf(el),
            this.selection.layers.length - 1
          );
        }

        if (el.temp_layer_type === "Point") {
          this.array_move(this.selection.layers.indexOf(el), 0);
        }
        if (el.temp_layer_type === "LineString") {
          this.array_move(this.selection.layers.indexOf(el), 1);
        }
        if (
          el.temp_layer_type === "Polygon" ||
          el.temp_layer_type === "MultiPolygon"
        ) {
          this.array_move(this.selection.layers.indexOf(el), 2);
        }
      });
    },
    array_move(old_index, new_index) {
      if (new_index >= this.selection.layers.length) {
        var k = new_index - this.selection.layers.length + 1;
        while (k--) {
          this.selection.layers.push(undefined);
        }
      }
      this.selection.layers.splice(
        new_index,
        0,
        this.selection.layers.splice(old_index, 1)[0]
      );
    },
    arraymove(fromIndex, toIndex) {
      var element = this.selection.layers[fromIndex];
      this.selection.layers.splice(fromIndex, 1);
      this.selection.layers.splice(toIndex, 0, element);
    },

    removeDuplicatePolygon() {
      let count = 0;
      let dup_layer;
      let polygon_layer;
      this.selection.layers.forEach((layer) => {
        if (layer.File_Type.Filetype !== "Raster") {
          if (
            layer.temp_layer_type == "MultiPolygon" ||
            layer.temp_layer_type == "Polygon"
          ) {
            count++;
            if (count == 1) {
              polygon_layer = layer;
            }

            if (count > 1) {
              dup_layer = layer;
              return;
            }
          }
        }
      });

      if (count > 1) {
        const index = this.selection.layers.indexOf(dup_layer);
        this.selection.layers.splice(index, 1);
        this.$toast.error(
          `Multiple polygons not allowed. Plotting ${polygon_layer.Layer_Name}`
        );
      }
    },

    async startExport() {
      this.exportingMap = true;
      this.tileLayer = false;
      this.layerOpacity = 1;

      setTimeout(() => {
        this.exportMap();
      }, 500);

      setTimeout(() => {
        this.layerOpacity = 0.8;
      }, 2000);
    },
    async exportMap() {
      let olzoom = document.getElementsByClassName("ol-zoom")[0];
      olzoom.style.display = "none";
      document.getElementById("my_legend").style.display = "none";

      document.getElementById("export_layer").style.display = "block";

      let doc = document.getElementById("export_layer");
      await toPng(this.$refs.document, {
        backgroundColor: "#FFFFFF",
        quality: 1,
      }).then((mapImgUrl) => {
        this.mapImgSource = mapImgUrl;
        this.$emit("mapImg", this.mapImgSource);
      });

      await toPng(doc, {
        backgroundColor: "#FFFFFF",
        quality: 1,
      }).then((url) => {
        download(url, "exportedMap.png");
      });

      document.getElementById("export_layer").style.display = "none";
      document.getElementById("my_legend").style.display = "block";
      olzoom.style.display = "block";

      this.exportingMap = false;
      this.tileLayer = true;
    },
    pointOnSurface: findPointOnSurface,
    async onGeotiffLoaded(layer) {
      const response = await fetch(layer.document);
      const arrayBuffer = await response.arrayBuffer();
      const tiff = await fromArrayBuffer(arrayBuffer);

      const image = await tiff.getImage();
      const boundingBoxData = image.getBoundingBox();
      const imageData = await image.readRasters();
      let noData = image.fileDirectory.GDAL_NODATA.replace("\u0000", "");
      // const valueToRemove = parseInt(noData);
      const dataset = imageData[0];

      for (let i = 0; i < dataset.length; i++) {
        if (parseInt(dataset[i]) == parseInt(noData)) {
          dataset[i] = null;
        }
      }

      // format = data:array(pixels), width:int, height:int
      const uniquePixelData = [...new Set(dataset)];

      let index = uniquePixelData.indexOf(0);
      if (index > -1) {
        uniquePixelData.splice(index, 1);
      }

      // uniquePixelData.shift();
      let canvas = document.createElement("canvas");
      const minValue = this.arrayMin(uniquePixelData); //Math.min(...uniquePixelData);
      const maxValue = this.arrayMax(uniquePixelData); //Math.max(...uniquePixelData);

      this.raterRangeCalculator(minValue, maxValue, layer);

      const average = (array) => array.reduce((a, b) => a + b) / array.length;

      const avg = average(uniquePixelData) * image.getWidth();
      const divider = (maxValue / avg - minValue / avg) / 5;

      const first = minValue / avg;
      const second = first + divider;
      const third = second + divider;
      const fourth = third + divider;
      const fifth = fourth + divider;

      let range = [];

      range.push(first, second, third, fourth, fifth);

      plotty.addColorScale(
        "mycolorscale",
        ["#FF7F7F", "#377eb8", "#4daf4a", "#FFFF00", "#ff7f00"],
        [0, 0.25, 0.5, 0.75, 1]
      );

      const plot = new plotty.plot({
        canvas: canvas,
        data: dataset,
        width: image.getWidth(),
        height: image.getHeight(),
        domain: [minValue, maxValue],
        displayRange: [minValue, maxValue],
        applyDisplayRange: true,
        colorScale: "mycolorscale",
        clampLow: true,
        clampHigh: true,
        useWebGL: true,
      });

      plot.render();

      const imgSource = new ImageStatic({
        url: canvas.toDataURL(),
        imageExtent: boundingBoxData,
        projection: "EPSG:4326",
      });
      let geotiffLayer = new ImageLayer();
      geotiffLayer.set("name", layer.Layer_Name);
      geotiffLayer.set("type", "Image");

      geotiffLayer.setZIndex(1);

      geotiffLayer.setSource(imgSource);
      geotiffLayer.setOpacity(0.9);

      this.$refs.map.$map.addLayer(geotiffLayer);
    },
    arrayMin(arr) {
      let len = arr.length,
        min = Infinity;
      while (len--) {
        if (arr[len] < min) {
          min = arr[len];
        }
      }
      return min;
    },
    arrayMax(arr) {
      let len = arr.length,
        max = -Infinity;
      while (len--) {
        if (arr[len] > max) {
          max = arr[len];
        }
      }
      return max;
    },
  },
};
</script>

<style scoped>
::v-deep .ol-scale-bar-inner>div>div.ol-scale-step-text {
  padding-top: 10px !important;
}

::v-deep .ol-scale-step-text {
  position: unset !important;
  font-size: 10px !important;
}

.my_map_box {
  position: relative;
}

.my_compass {
  width: 90px;
  height: 90px;
  object-fit: contain;
  position: absolute;
  right: 1rem;
  bottom: 1rem;
}

.my_legend {
  position: absolute;
  top: 0;
  right: 0;
}

.legend_layer_title {
  font-weight: 700;
  color: #00749e;
}

.legend_selected_attribute {
  font-weight: 700;
}

.li_skeleton {
  color: #efefef;
  background-color: #efefef;
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

.li_skeleton_white {
  color: #ffffff;
  background-color: #ffffff;
  animation: pulse 1s cubic-bezier(0.4, 0, 0.6, 1) infinite;
}

@keyframes pulse {
  50% {
    opacity: 0.9;
  }
}

.legendbox {
  font-size: 90%;
  background-color: #ffffff;
  height: 15rem;
  min-width: 12rem;
  overflow-y: scroll;
}

.category_listing_box {
  height: 50vh;
}

.category_list {
  height: 40vh;
}

.category_listing_box,
.category_list {
  overflow-y: scroll;
  overflow-x: hidden;
}

.category_label>strong {
  text-decoration: underline;
}

.manage_category {
  padding: 10px 20px;
  border-radius: 0 21px !important;
}

.view_layers {
  padding: 10px 20px;
  border-radius: 21px 0 !important;
}

.manage_layers {
  padding: 10px 20px;
  border-radius: 0 21px !important;
}

.point {
  border-radius: 50%;
  background-color: red;
}

.point-1 {
  height: 10px;
  width: 10px;
}

.point-2 {
  height: 12px;
  width: 12px;
}

.point-3 {
  height: 14px;
  width: 14px;
}

.point-4 {
  height: 16px;
  width: 16px;
}

.point-5 {
  height: 18px;
  width: 18px;
}

.mappara {
  height: inherit;
}

.subcategory_list {
  padding-left: 30px;
  color: #00749e;
}

.subcategory_list i {
  padding-right: 7px;
}

.category_layers {
  padding-left: 50px;
}
</style>
