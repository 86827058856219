import axios from "axios";
import {baseDomain} from '../../constants/constant'
const baseURL = `${baseDomain}/apiv1`;

const token = localStorage.getItem('userToken')

let headers = {
    "Content-Type": "application/x-www-form-urlencoded"
}

if (token) {
    headers.Authorization = `Token ${token}`
}

export default axios.create({
    baseURL,
    headers
});
