var render = function render(){var _vm=this,_c=_vm._self._c;return _c('validation-observer',{ref:"observer",scopedSlots:_vm._u([{key:"default",fn:function({ handleSubmit }){return [_c('form',{on:{"submit":function($event){$event.preventDefault();return handleSubmit(_vm.submit)}}},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"layer name","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine }){return [_c('label',{staticClass:"form-label",attrs:{"for":"layer-name"}},[_vm._v("Layer Name")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Layer_Name),expression:"form.Layer_Name"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"layer-name","placeholder":"Layer Name"},domProps:{"value":(_vm.form.Layer_Name)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Layer_Name", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"document","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine, validate }){return [_c('label',{staticClass:"form-label",attrs:{"for":"document"}},[_vm._v("Document")]),_c('input',{ref:"file",staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"file","id":"document"},on:{"input":validate,"change":_vm.processFile}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"description","rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine }){return [_c('label',{staticClass:"form-label",attrs:{"for":"description"}},[_vm._v("Description")]),_c('textarea',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.Description),expression:"form.Description"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"description"},domProps:{"value":(_vm.form.Description)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "Description", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"keywords","rules":""},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine }){return [_c('label',{staticClass:"form-label",attrs:{"for":"keywords"}},[_vm._v("Keywords")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.keywords),expression:"form.keywords"}],staticClass:"form-control",class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"type":"text","id":"keywords","placeholder":"Keywords"},domProps:{"value":(_vm.form.keywords)},on:{"input":function($event){if($event.target.composing)return;_vm.$set(_vm.form, "keywords", $event.target.value)}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"File Type","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine }){return [_c('label',{staticClass:"form-label"},[_vm._v("File Type")]),_c('v-select',{class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"options":_vm.options.fileTypes,"label":"Filetype","placeholder":"--Select File Type--","reduce":(item) => item.Filetype},model:{value:(_vm.form.File_Type),callback:function ($$v) {_vm.$set(_vm.form, "File_Type", $$v)},expression:"form.File_Type"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"Category","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine }){return [_c('label',{staticClass:"form-label"},[_vm._v("Category")]),_c('v-select',{class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"options":_vm.options.categories,"label":"Category","placeholder":"--Select Category--","reduce":(item) => item.Category},model:{value:(_vm.form.Category),callback:function ($$v) {_vm.$set(_vm.form, "Category", $$v)},expression:"form.Category"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"subcategory","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine }){return [_c('label',{staticClass:"form-label"},[_vm._v("Sub-Category")]),_c('v-select',{class:{
              'is-invalid': errors[0],
              'is-valid': !errors[0] && !pristine,
            },attrs:{"options":_vm.options.subcategories,"label":"title","placeholder":"--Select Sub Category--","reduce":(item) => item,"disabled":!_vm.form.Category},model:{value:(_vm.form.subcategory),callback:function ($$v) {_vm.$set(_vm.form, "subcategory", $$v)},expression:"form.subcategory"}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1),(_vm.$store.state.auth.isSuperuser || _vm.$store.state.auth.isAdmin)?_c('div',{staticClass:"col-12 mb-3"},[_c('validation-provider',{attrs:{"name":"in_draft","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function({ errors, pristine }){return [_c('label',{staticClass:"form-label"},[_vm._v("Publish ")]),_c('input',{directives:[{name:"model",rawName:"v-model",value:(_vm.form.in_draft),expression:"form.in_draft"}],class:{
              'is-invalid': errors.length > 0,
              'is-valid': errors.length === 0 && !pristine,
            },attrs:{"type":"checkbox","label":"Publish"},domProps:{"checked":Array.isArray(_vm.form.in_draft)?_vm._i(_vm.form.in_draft,null)>-1:(_vm.form.in_draft)},on:{"change":function($event){var $$a=_vm.form.in_draft,$$el=$event.target,$$c=$$el.checked?(true):(false);if(Array.isArray($$a)){var $$v=null,$$i=_vm._i($$a,$$v);if($$el.checked){$$i<0&&(_vm.$set(_vm.form, "in_draft", $$a.concat([$$v])))}else{$$i>-1&&(_vm.$set(_vm.form, "in_draft", $$a.slice(0,$$i).concat($$a.slice($$i+1))))}}else{_vm.$set(_vm.form, "in_draft", $$c)}}}}),_c('div',{staticClass:"invalid-feedback"},[_vm._v(" "+_vm._s(errors[0])+" ")])]}}],null,true)})],1):_vm._e()]),_c('div',{staticClass:"col-12 mb-3"},[(!_vm.saving)?_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"submit"}},[_vm._v(" Save ")]):_c('button',{staticClass:"btn btn-primary btn-sm px-2 me-3",attrs:{"type":"button","disabled":""}},[_c('span',{staticClass:"spinner-border spinner-border-sm",attrs:{"role":"status","aria-hidden":"true"}}),_vm._v(" Loading... ")]),_c('button',{staticClass:"btn btn-sm btn-danger px-2",on:{"click":function($event){return _vm.$emit('cancel')}}},[_vm._v(" Cancel ")])])])]}}])})
}
var staticRenderFns = []

export { render, staticRenderFns }