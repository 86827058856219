import { render, staticRenderFns } from "./WaterQualityViewModal.vue?vue&type=template&id=06c7ab30&scoped=true"
import script from "./WaterQualityViewModal.vue?vue&type=script&lang=js"
export * from "./WaterQualityViewModal.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "06c7ab30",
  null
  
)

export default component.exports