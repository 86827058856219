<template>
  <div class="mainnav">
    <!-- Topbar -->
    <div class="navcolor navbar-top d-lg-block small">
      <div class="container">
        <div class="d-md-flex justify-content-between align-items-center">
          <!-- Top bar left -->
          <div class="toplogo">
            <router-link class="d-flex align-items-center" :to="{ name: 'home' }">
              <img src="@/assets/img/logo.png" alt="KVWS" class="logo-img" />
            </router-link>
          </div>
          <!-- Top bar center -->
          <div class="office-heading">
            <div class="officeName">
              <h4 class="gov">Gandaki Province Government</h4>
              <h1 class="office-name">Gandaki Provincial Policy and Planning Commission</h1>
              <h4 class="gov">Pokhara, Nepal</h4>
            </div>
          </div>
          <!-- Top bar right -->
          <!-- Snippet Added -->
          <div class="topbar-right">
            <img src="@/assets/img/nepal_flag.gif" alt="Nepal Flag" class="flag-img" />
          </div>
          <!-- End of Snippet -->
        </div>
      </div>
    </div>

    <!-- Navigation -->
    <nav class="navbar navbar-expand-lg navbar-light shadow-sm" id="mainNav">
      <div class="container">
        <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarResponsive"
          aria-controls="navbarResponsive" aria-expanded="false" aria-label="Toggle navigation">
          Menu
          <i class="bi-list"></i>
        </button>
        <div class="collapse navbar-collapse mynav" id="navbarResponsive">
          <ul class="navbar-nav m-auto my-3 my-lg-0">
            <li class="nav-item">
              <router-link class="nav-link me-lg-3" :to="{ name: 'home' }">
                Home
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link me-lg-3" :to="{ name: 'dataset' }">
                Dataset
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link me-lg-3" :to="{ name: 'time-series' }">
                Time Series
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link me-lg-3" :to="{ name: 'location-data' }">
                Location Data
              </router-link>
            </li>
            <li class="nav-item">
              <router-link class="nav-link me-lg-3" :to="{ name: 'study-reports' }">
                Study & Reports
              </router-link>
            </li>
          </ul>

          <div class="search me-5">
            <form class="float-right" @submit.prevent="handleSearch">
              <input class="form-control m-2" v-model="searchQuery" style="border-radius: 5px;" type="search"
                placeholder="Search Datasets" aria-label="Search">
            </form>
          </div>

          <div class="udername text-white">
            <router-link :to="{ name: 'login' }" class="text-capitalize custom-login"
              v-if="!$store.state.auth.isLoggedIn">
              Login
            </router-link>
            <template v-else>
              <a class="nav-link dropdown-toggle text-white" href="#" id="navbarDropdown" role="button"
                data-bs-toggle="dropdown" aria-expanded="false">
                <img v-if="$store.state.auth.photo && $store.state.auth.photo !== undefined"
                  :src="$store.state.auth.photo" class="img-fluid img-xs rounded-circle text-capitalize"
                  alt="Profile Photo" />
                <img v-else src="../../assets/img/profile.png" class="img-fluid img-xs rounded-circle mb-0"
                  alt="Profile Photo" />
                {{ $store.state.auth.username }}
              </a>
              <ul class="dropdown-menu" aria-labelledby="navbarDropdown">
                <li>
                  <router-link class="dropdown-item" :to="{ name: 'dataset' }">
                    Dataset
                  </router-link>
                </li>
                <li>
                  <router-link class="dropdown-item" :to="{ name: 'study-reports' }">
                    Study & Reports
                  </router-link>
                </li>
                <li>
                  <hr class="dropdown-divider" />
                </li>
                <li>
                  <a href="#" @click.prevent="logout" class="dropdown-item">
                    Logout
                  </a>
                </li>
              </ul>
            </template>
          </div>
        </div>
      </div>
    </nav>
  </div>
</template>

<script>
export default {
  name: "TheNavigation",
  data() {
    return {
      searchQuery: ''
    };
  },
  watch: {
    '$route'(to) {
      if (to.name !== 'dataset' && localStorage.getItem('searchParam')) {
        // Trigger any action you need when the route changes to 'dataset'
        location.reload();
      }
    }
  },
  methods: {
    handleSearch() {
      localStorage.setItem('searchParam', this.searchQuery);
      if (this.$route.name == 'dataset') {
        location.reload();
      } else {
        this.$router.push({ name: 'dataset' });
      }
    },
    async logout() {
      await this.$store.dispatch("auth/logout");
    },
  },
};
</script>

<style scoped>
.navcolor {
  background-color: white !important;
}

#mainNav {
  background-color: blue !important;
}

.custom-login {
  color: white !important;
}

.navbar-nav .nav-link {
  color: white !important;
  font-weight: bold;
}

.dropdown-menu[data-bs-popper] {
  top: 100%;
  left: unset;
  right: 0;
  margin-top: 0.125rem;
}

.toplogo {
  text-align: left;
  flex: 1;
}

.logo-img {
  max-width: 100px;
  margin-bottom: 10px;
}

.office-heading {
  text-align: center;
  flex: 2;
}

.officeName h4.gov,
.officeName h1.office-name {
  color: red;
  /* Set text color to red */
}

.officeName h4.gov {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.officeName h1.office-name {
  margin: 0;
  font-size: 2.5em;
  font-weight: bold;
}

.officeName h4.gov:last-of-type {
  margin: 0;
  font-size: 1.5em;
  font-weight: bold;
}

.topbar-right {
  flex: 1;
  text-align: right;
}

/* Snippet Added */
.flag-img {
  max-width: 65px;
}

/* End of Snippet */
</style>
