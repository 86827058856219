var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"col-xl-6 col-md-6 mb-4"},[_vm._m(0),_c('div',{staticClass:"card shadow border-0 card-widget h-100"},[_c('div',{staticClass:"card-body d-block align-items-center justify-content-between"},[_vm._m(1),_c('div',{staticClass:"maptext"},[_c('h3',[_vm._v(_vm._s(_vm.totalLayers))])])])])]),_c('div',{staticClass:"col-xl-6 col-md-6 mb-4"},[_vm._m(2),_c('div',{staticClass:"card shadow border-0 card-widget h-100"},[_c('div',{staticClass:"card-body d-block align-items-center justify-content-between"},[_vm._m(3),_c('div',{staticClass:"maptext"},[_c('h3',[_vm._v(_vm._s(_vm.totalVariables))])])])])]),_c('div',{staticClass:"col-xl-6 col-md-6 mb-4"},[_vm._m(4),_c('div',{staticClass:"card shadow border-0 card-widget h-100"},[_c('div',{staticClass:"card-body d-block align-items-center justify-content-between"},[_vm._m(5),_c('div',{staticClass:"maptext"},[_c('h3',[_vm._v(_vm._s(_vm.totalTimeSeries))])])])])]),_c('div',{staticClass:"col-xl-6 col-md-6 mb-4"},[_vm._m(6),_c('div',{staticClass:"card shadow border-0 card-widget h-100"},[_c('div',{staticClass:"card-body d-block align-items-center justify-content-between"},[_vm._m(7),_c('div',{staticClass:"maptext"},[_c('h3',[_vm._v(_vm._s(_vm.totalCategory))])])])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon bg-indigo"},[_c('i',{staticClass:"bi bi-bookmarks-fill"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('h6',{staticClass:"mb-0 fw-bold text-black"},[_vm._v("Total Layers")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon bg-indigo"},[_c('i',{staticClass:"bi bi-bounding-box"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('h6',{staticClass:"mb-0 fw-bold text-black"},[_vm._v("Total Variables")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon bg-indigo"},[_c('i',{staticClass:"bi bi-clock-history"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('h6',{staticClass:"mb-0 fw-bold text-black"},[_vm._v("Total Timeseries")])])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"icon bg-indigo"},[_c('i',{staticClass:"bi bi-moisture"})])
},function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"text"},[_c('h6',{staticClass:"mb-0 fw-bold text-black"},[_vm._v("Total Categories")])])
}]

export { render, staticRenderFns }