import { render, staticRenderFns } from "./TotalCount.vue?vue&type=template&id=314ad9bb&scoped=true"
import script from "./TotalCount.vue?vue&type=script&lang=js"
export * from "./TotalCount.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "314ad9bb",
  null
  
)

export default component.exports